import { Toast } from 'primereact/toast'
import React, { useEffect, useRef, useState } from 'react'
import api from '../../services/api'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import 'dayjs/locale/pt-br'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag'

const Turmas = ({ data_inicial, data_final, initBuscarItens, onBuscarItensEnd }) => {
    const [loading, setLoading] = useState(false)
    const toast = useRef(null)
    const [data, setData] = useState([])

    const buscarItens = async () => {
        try {
            setLoading(true)
            const response = await api.get('/api/turmas?data_inicio=' + dayjs(data_inicial).format('DD/MM/YYYY') + '&data_fim=' + dayjs(data_final).format('DD/MM/YYYY'))
            setData(response.data)
        } catch (e) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: e.response?.data?.message || 'Erro ao realizar operação', life: 3000 });
        } finally {
            setLoading(false)
            onBuscarItensEnd()
        }
    }

    useEffect(() => {
        if (initBuscarItens) {
            buscarItens()
        }
    }, [initBuscarItens])

    return (
        <div>
            <Toast ref={toast} position="top-center" />

            {loading && (
                <div className='flex justify-center items-center'>
                    <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>
                </div>
            )}

            {!loading && data.length > 0 && (
                <div className="chart-container" style={{ minHeight: '620px', border: '1px solid #ddd'}}>
                    <DataTable value={data} tableStyle={{ width: '100%' }} showGridlines paginator rows={10} draggable={false}>
                        <Column field="nome" header="Nome"></Column>
                        <Column field="data_inicio" header="Início"></Column>
                        <Column field="data_fim" header="Fim"></Column>
                        <Column field="professor" header="Professor"></Column>
                        <Column field="alunos" header="Alunos" bodyClassName="text-center"></Column>
                        <Column field="status" header="Status" bodyClassName="text-center" body={(rowData) => {
                            if (rowData.status === 'EM ANDAMENTO') {
                                return <Tag severity="warning" value="Em andamento" />
                            } else {
                                return <Tag severity="success" value="Concluído" />
                            }
                        }}></Column>
                        <Column field="custo" header="Custo" bodyClassName="text-right" body={(rowData) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(rowData.custo)}></Column>
                        <Column field="receita" header="Receita" bodyClassName="text-right" body={(rowData) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(rowData.receita)}></Column>
                        <Column field="lucro" header="Lucro" bodyClassName="text-right" body={(rowData) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(rowData.lucro)}></Column>
                    </DataTable>
                </div>
            )}
        </div>
    )
}

export default Turmas