import { Toast } from 'primereact/toast'
import React, { useEffect, useRef, useState } from 'react'
import api from '../../services/api'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import 'dayjs/locale/pt-br'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { Knob } from 'primereact/knob'
import { Card } from 'primereact/card'
dayjs.extend(customParseFormat)

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend)

const Despesas = ({ data_inicial, data_final, initBuscarItens, onBuscarItensEnd }) => {
    const [loading, setLoading] = useState(false)
    const toast = useRef(null)
    const [data, setData] = useState([])
    const coresLegais = ['#17A2B8', '#28A745', '#FFC107', '#F56954', '#007BFF', '#6C757D', '#6610f2', '#DC3545', '#FF5733', '#FFC300']

    const buscarItens = async () => {
        try {
            setLoading(true)
            const response = await api.get('/api/despesas?data_inicio=' + dayjs(data_inicial).format('DD/MM/YYYY') + '&data_fim=' + dayjs(data_final).format('DD/MM/YYYY'))
            setData(response.data)
        } catch (e) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: e.response?.data?.message || 'Erro ao realizar operação', life: 3000 });
        } finally {
            setLoading(false)
            onBuscarItensEnd()
        }
    }

    useEffect(() => {
        if (initBuscarItens) {
            buscarItens()
        }
    }, [initBuscarItens])

    return (
        <div>
            <Toast ref={toast} position="top-center" />

            {loading && (
                <div className='flex justify-center items-center'>
                    <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>
                </div>
            )}

            {!loading && data.total > 0 && (
                <div className="chart-container">
                    <Bar
                        options={{
                            scales: {
                                y: {
                                    stacked: true,
                                    beginAtZero: true
                                },
                                x: {
                                    stacked: true
                                }
                            },
                            plugins: {
                                datalabels: {
                                    display: true,
                                    align: 'top',
                                    color: '#000',
                                    font: {
                                        weight: 'bold'
                                    },
                                    formatter: function (value, context) {
                                        return value ? Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value) : 0
                                    }
                                }
                            }
                        }}
                        data={{
                            labels: data.porMes.map(d => d.data),
                            datasets: Object.keys(data.porCategoria).map((categoria, index) => {
                                return {
                                    label: categoria,
                                    data: data.porMes.map(d => d.porCategoria[categoria]),
                                    backgroundColor: coresLegais[index],
                                }
                            })
                        }}
                    />

                    <div id='totais' className='mt-5 grid' style={{ justifyContent: 'center' }}>
                        {Object.keys(data.porCategoria).map((categoria, index) => (
                            <div className="col-6 md:col-3 lg:2">
                                <Card value={data.porCategoria[categoria]} title={categoria} subTitle={Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.porCategoria[categoria])} />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

export default Despesas