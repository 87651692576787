import { Button } from 'primereact/button'
import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Login from './Login'
import { Toast } from 'primereact/toast'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import 'dayjs/locale/pt-br'

import { Calendar } from 'primereact/calendar'
import { Divider } from 'primereact/divider'
import Inscricoes from './Inscricoes'
import Receita from './Receita'
import Turmas from './Turmas'
import Despesas from './Despesas'
import Resultado from './Resultado'
import Competencias from './Competencias'

dayjs.extend(customParseFormat)

const Index = () => {

    const [token, setToken] = React.useState(null)
    const [showLogin, setShowLogin] = React.useState(false)
    const toast = useRef(null)

    const [data_inicio, setDataInicio] = React.useState()
    const [data_fim, setDataFim] = React.useState()

    const [loading, setLoading] = React.useState(false)
    const [initBuscarItens, setInitBuscarItens] = React.useState(false)

    useEffect(() => {
        const token = localStorage.getItem('ifpk-token')

        if (!token) {
            setShowLogin(true)
        } else {
            setToken(token)
        }
    }, [])

    useEffect(() => {
        setDataInicio(dayjs().subtract(1, 'year').add(1, 'month').startOf('month').toDate())
        setDataFim(dayjs().endOf('month').toDate())
        setInitBuscarItens(true)
    }, [])

    const buscarDados = async () => {
        try {
            setLoading(true)
            setInitBuscarItens(true)
        } catch (e) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: e.response?.data?.message || 'Erro ao realizar operação', life: 3000 });
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Toast ref={toast} position="top-center" />

            <Login showLogin={showLogin} onClose={() => setShowLogin(false)} onLogin={(token) => {
                localStorage.setItem('ifpk-token', token)
                setToken(token)
                setShowLogin(false)
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Login realizado com sucesso', life: 3000 });
            }} />

            {token && (
                <>
                    <div className='p-container pt-5'>
                        <div className='grid'>
                            <div className="col-12 md:col-12 lg:col-4">
                                <div className="flex flex-column gap-2">
                                    <label>Período Inicial</label>
                                    <Calendar
                                        value={data_inicio}
                                        onChange={(e) => setDataInicio(e.value)}
                                        dateFormat='dd/mm/yy'
                                        showIcon={true}
                                        mask='99/99/9999'
                                    />
                                </div>
                            </div>

                            <div className="col-12 md:col-12 lg:col-4">
                                <div className="flex flex-column gap-2">
                                    <label>Período Final</label>
                                    <Calendar
                                        value={data_fim}
                                        onChange={(e) => setDataFim(e.value)}
                                        dateFormat='dd/mm/yy'
                                        showIcon={true}
                                        mask='99/99/9999'
                                    />
                                </div>
                            </div>

                            <div className="col-12 md:col-12 lg:col-4">
                                <div className="flex flex-column gap-2">
                                    <label>&nbsp;</label>
                                    <Button label="Buscar Dados" className='w-full' loading={loading} onClick={buscarDados} />
                                </div>
                            </div>

                            <Divider />

                            <div className="col-12 md:col-12 lg:12">
                                <h2>Matrículas no Período</h2>

                                {data_inicio && data_fim && (
                                    <Inscricoes
                                        data_inicial={data_inicio}
                                        data_final={data_fim}
                                        initBuscarItens={initBuscarItens}
                                        onBuscarItensEnd={() => setInitBuscarItens(false)}
                                    />
                                )}
                            </div>

                            <div className="col-12 md:col-12 lg:12 mt-5 pt-5">
                                <h2>Receita no Período</h2>

                                {data_inicio && data_fim && (
                                    <Receita
                                        data_inicial={data_inicio}
                                        data_final={data_fim}
                                        initBuscarItens={initBuscarItens}
                                        onBuscarItensEnd={() => setInitBuscarItens(false)}
                                    />
                                )}
                            </div>

                            <div className="col-12 md:col-12 lg:12 mt-5 pt-5">
                                <h2>Análise de Turmas</h2>

                                {data_inicio && data_fim && (
                                    <Turmas
                                        data_inicial={data_inicio}
                                        data_final={data_fim}
                                        initBuscarItens={initBuscarItens}
                                        onBuscarItensEnd={() => setInitBuscarItens(false)}
                                    />
                                )}
                            </div>

                            <div className="col-12 md:col-12 lg:12 mt-5 pt-5">
                                <h2>Custos e Despesas</h2>

                                {data_inicio && data_fim && (
                                    <Despesas
                                        data_inicial={data_inicio}
                                        data_final={data_fim}
                                        initBuscarItens={initBuscarItens}
                                        onBuscarItensEnd={() => setInitBuscarItens(false)}
                                    />
                                )}
                            </div>

                            <div className="col-12 md:col-12 lg:12 mt-5 pt-5">
                                <h2>Análise de Resultado</h2>

                                {data_inicio && data_fim && (
                                    <Resultado
                                        data_inicial={data_inicio}
                                        data_final={data_fim}
                                        initBuscarItens={initBuscarItens}
                                        onBuscarItensEnd={() => setInitBuscarItens(false)}
                                    />
                                )}
                            </div>

                            <div className="col-12 md:col-12 lg:12 mt-5 pt-5">
                                <h2>Valor Competência</h2>

                                {data_inicio && data_fim && (
                                    <Competencias
                                        data_inicial={data_inicio}
                                        data_final={data_fim}
                                        initBuscarItens={initBuscarItens}
                                        onBuscarItensEnd={() => setInitBuscarItens(false)}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}

        </>
    )
}

export default Index