import { createBrowserRouter } from "react-router-dom"
import Index from "./pages/index/Index"

const router = createBrowserRouter([
  {
    path: '/',
    element: <Index/>
  }
])

export default router