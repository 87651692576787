import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import React, { useRef, useState } from 'react'
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import api from '../../services/api';

const Login = ({ showLogin, onClose, onLogin }) => {

    const [loginData, setLoginData] = useState({})
    const [loading, setLoading] = useState(false)

    const toast = useRef(null)

    const doLogin = async () => {
        try {
            setLoading(true)
            const response = await api.post('/api/login', loginData)

            setLoginData({})
            onLogin(response.data.token)
        } catch (e) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: e.response?.data?.message || 'Erro ao realizar operação', life: 3000 });
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Toast ref={toast} position="top-center"/>
            <Dialog header="Fazer Login" visible={showLogin} style={{ minWidth: '50vw' }} onHide={() => onClose()}>
                <div className='grid'>
                    <div className="col-12 md:col-6 lg:col-6">
                        <div className="flex flex-column gap-2">
                            <label>E-mail</label>
                            <IconField iconPosition="left">
                                <InputIcon className="pi pi-user"></InputIcon>
                                <InputText className='w-full' onChange={(e) => setLoginData({ ...loginData, email: e.target.value })} />
                            </IconField>
                        </div>
                    </div>

                    <div className="col-12 md:col-6 lg:col-6">
                        <div className="flex flex-column gap-2">
                            <label>Senha</label>
                            <IconField iconPosition="left">
                                <InputIcon className="pi pi-key"></InputIcon>
                                <InputText className='w-full' type='password' onChange={(e) => setLoginData({ ...loginData, password: e.target.value })} />
                            </IconField>
                        </div>
                    </div>

                    <div className="col-12 md:col-12 lg:col-12">
                        <Button label="Entrar" className='w-full' onClick={doLogin} loading={loading} />
                    </div>
                </div>
            </Dialog >
        </>
    )
}

export default Login