import { Toast } from 'primereact/toast'
import React, { useEffect, useRef, useState } from 'react'
import api from '../../services/api'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import 'dayjs/locale/pt-br'
import { Line } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

dayjs.extend(customParseFormat)

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ChartDataLabels)

const Inscricoes = ({ data_inicial, data_final, initBuscarItens, onBuscarItensEnd }) => {
    const [loading, setLoading] = useState(false)
    const toast = useRef(null)
    const [data, setData] = useState([])

    const buscarItens = async () => {
        try {
            setLoading(true)
            const response = await api.get('/api/inscricoes?data_inicio=' + dayjs(data_inicial).format('DD/MM/YYYY') + '&data_fim=' + dayjs(data_final).format('DD/MM/YYYY'))
            setData(response.data)
        } catch (e) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: e.response?.data?.message || 'Erro ao realizar operação', life: 3000 });
        } finally {
            setLoading(false)
            onBuscarItensEnd()
        }
    }

    useEffect(() => {
        if (initBuscarItens) {
            buscarItens()
        }
    }, [initBuscarItens])

    return (
        <div>
            <Toast ref={toast} position="top-center" />

            {loading && (
                <div className='flex justify-center items-center'>
                    <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>
                </div>
            )}

            {!loading && data.length > 0 && (
                <div className="chart-container">
                    <Line
                        options={{
                            scales: {
                                y: {
                                    beginAtZero: true
                                }
                            },
                            plugins: {
                                datalabels: {
                                    display: true,
                                    align: 'top',
                                    color: '#000',
                                    font: {
                                        weight: 'bold'
                                    }
                                }
                            }
                        }}
                        data={{
                            labels: data.map(d => d.mes),
                            datasets: [
                                {
                                    label: 'Inscritos',
                                    data: data.map(d => d.total),
                                    fill: false,
                                    backgroundColor: 'rgb(75, 192, 192)',
                                    borderColor: 'rgba(75, 192, 192)',
                                    tension: 0.3
                                },
                            ],
                        }}
                    />
                </div>
            )}
        </div>
    )
}

export default Inscricoes